import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../action-button';
import ItemManagement from '../../outlet/outlet-container';
import ReactPaginate from 'react-paginate';
import { getAllOutlet, allSearch } from '../../api/apiService';
import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';
import { colors } from '@material-ui/core';

const mapStateToProps = (store) => {
  return {
    loaderDisplay: store.loaderDisplay
  }
}
class OutletTable extends React.Component {
  constructor() {
    super();
    this.state = {
      outlets: [],
      countOutlet: {},
      countPage: null,
    }
    this.getOutList(1);
  }
  handleOutletSearch = (e) => {
    if (e.target.value.length < 1) {
      this.getOutList(1);
    } else {
      this.outletSearch(e.target.value);
    }

  }
  outletPageFunc = (i) => {
    this.getOutList(i.selected + 1);
  }
  // Api call----------
  outletSearch = async (txt) => {
    try {
      const search = await allSearch("outlet", encodeURIComponent(txt));
      this.setState({ outlets: search.data.data })
    } catch (ex) {
      console.log(ex);
    }
  }
  getOutList(pagNo) {
    var self = this;
    store.dispatch(loader(true));
    getAllOutlet(pagNo).then(function (response) {
      self.setState({
        outlets: response.data.data,
        countOutlet: response.data,
        countPage: response.data.pageInfo.maxPageNo,
      });
      store.dispatch(loader(false));
    })
      .catch(function (error) {
        console.log(error)
        store.dispatch(loader(false));
      });
  }
  render() {
    return (
      <>
        <ItemManagement demoPathName={"/outlets"} outletPathName={"Outlet"}>
          <div className="mt-4 d-sm-flex justify-content-between">
            <div className="d-flex align-items-center justify-content-between notifi-box cursor-pointer"
            style={{backgroundColor:"blue "}}
              onClick={() => this.props.history.push(`/outlets/new-outlet/?type=new`)}
            >
              <div>
                <div className="icon-container outlet-icon-1"><i className="demo-icon icon-add font-20" style={{color:'blue'}}>&#xe802;</i></div>
              </div>
              <div className="text-center">
                <div className="title" style={{color:'#fff'}}>Add</div>
                <div className="sub-title" style={{color:'#fff'}}>New outlet</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between notifi-box" style={{ background: "#e2fffa", color: "#3ee4ff" }}>
              <div>
                <i className="demo-icon icon-add-ons font-25">&#xe803;</i>
              </div>
              <div className="text-center">
                <div className="title">{this.state.countOutlet.totalOutlets}</div>
                <div className="sub-title">Total outlet</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between notifi-box" style={{ background: "#fbe0be", color: "#fa9417" }}>
              <div>
                <i className="demo-icon icon-add-ons font-20">&#xe803;</i>
              </div>
              <div className="text-center">
                <div className="title">{this.state.countOutlet.countDeactive}</div>
                <div className="sub-title">Unpublish outlet</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between notifi-box" style={{ background: "#d5fefb", color: "#8edd89" }}>
              <div>
                <i className="demo-icon icon-add-ons font-25">&#xe803;</i>
              </div>
              <div className="text-center">
                <div className="title">000</div>
                {/* <div className="title">{this.state.countOutlet.countDeactive}</div> */}
                <div className="sub-title">Publish outlet</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between notifi-box" style={{ background: "#d0fcd1", color: "#53f957" }}>
              <div><i className="demo-icon icon-add-ons font-25">&#xe803;</i></div>
              <div className="text-center">
                <div className="title">000</div>
                {/* <div className="title">{this.state.countOutlet.countDeactive}</div> */}
                <div className="sub-title">Open outlet</div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between notifi-box" style={{ background: "#fbd4c2", color: "#fe590d" }}>
              <div><i className="demo-icon icon-add-ons font-25">&#xe803;</i></div>
              <div className="text-center">
                <div className="title">000</div>
                {/* <div className="title">{this.state.countOutlet.countDeactive}</div> */}
                <div className="sub-title">Closed outlet</div>
              </div>
            </div>
          </div>
          <div className="category-container my-4">
            <div className="table-search">
              <div className="search-container">
                <input onChange={(e) => this.handleOutletSearch(e)}
                  // value={this.state.outletSearch} 
                  type="search" placeholder="Search.." name="search" autoFocus />
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-hover text-capitalize">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Restaurant Name</th>
                    <th>Store Address</th>
                    <th>Owner</th>
                    <th className="text-center">Complimantory</th>
                    <th className="text-center">Type</th>
                    <th>Open</th>
                    <th>Close</th>
                    <th>Breaking Time</th>
                    <th className="text-center">Publish / Unpublish</th>
                    <th className="text-center">status</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.outlets.map(item => {
                      return <tr key={item._id}>
                        <td><img className="img-fluid table-img" src={item.outlet_images ? item.outlet_images : "/assate/images/store-logo.png"} alt="" /></td>
                        <td>
                          <Link to={`/outlets/products/${item._id}`}>{item.outlet_name}</Link><br />
                          <b className="font-12">{item.offer ? `Offer ${item.offer.percentage ? `${item.offer.amount}%` : `₹${item.offer.amount}`}` : ""}</b>
                          {/* <b>Offer {item.offer.percentage ? `${item.offer.amount}%` : `₹${item.offer.amount}`}</b> */}
                        </td>
                        <td style={{ width: "26%" }}>
                          <div className="table-address">{item.address_details.address}</div>
                          <b className="font-12">{item.address_details.pincode}</b>
                        </td>
                        <td>{item.owner.full_name}<br />(<b style={{ color: "green" }}>{item.owner.mobile}</b>)</td>
                        <td className="text-center">
                          <b className="font-12">{!item.complementary || item.complementary === "null" ? "" : item.complementary.name}</b>
                        </td>
                        <td className="text-center">
                          <b className="font-12"  style={{color:"#000", textTransform:"uppercase"}}>{!item.resturentType || item.resturentType === "null" ? "" : item.resturentType}</b>
                        </td>
                        <td>{item.opening_time}</td>
                        <td>{item.closing_time}</td>
                        <td style={{ color: "#076868" }}>{item.break_start && item.break_end ? item.break_start + "-" + item.break_end : ""}</td>
                        <td className="text-center">{item.isActive ? <span className="badge badge-success">Publish</span> : <span className="badge badge-danger">Unpublish</span>}</td>
                        <td className="text-center">{item.isOpen ? <span className="badge badge-success">Open</span> : <span className="badge badge-danger">closed</span>}</td>
                        <td>
                          <span className="user-table-auction-item">
                            {/* <Link to={`/outlets/product-info/${item._id}`}>
                              <ActionButton>
                                <i className="demo-icon icon-add">&#xe802;</i>
                              </ActionButton>
                            </Link> */}
                            <Link to={`/outlets/new-outlet/${item._id}`}>
                              <ActionButton>
                                <i className="demo-icon icon-edit" style={{color:'blue'}}>&#xe80f;</i>
                              </ActionButton>
                            </Link>
                            {/* <ActionButton>
                            <i className="demo-icon icon-delete">&#xe80e;</i>
                          </ActionButton> */}
                          </span>
                        </td>
                      </tr>
                    })
                  }

                </tbody>
              </table>
            </div>
            <ReactPaginate
              previousLabel={'<<'}
              nextLabel={'>>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              pageCount={this.state.countPage}
              onPageChange={this.outletPageFunc}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        </ItemManagement>
      </>
    );
  }

}
export default connect(mapStateToProps)(OutletTable);
