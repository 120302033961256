import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { getAllOrders, searchOrders } from '../../api/apiService';
import { palyNotifyTone } from '.././notify-tone';
import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';
import Moment from 'react-moment';

const mapStateToProps = (state) => {
    return {
        loaderDisplay: state.loaderDisplay,
        socketId: state.loaderReducer.socketId
    }
}
class AllOrderTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oderList: [],
            pageCount: [],
            orderPageNo: 1,
            sTxt: this.props.searchTxt !== "" ? this.props.searchTxt : "",
            searchOrderData: []
        }
        if (this.props.socketId !== null) {
            this.initSocket();
        } else {
            setTimeout(() => {
                if (this.props.socketId !== null) {
                    this.initSocket();
                }
            }, 5000)
        }
        this.allOrders(true);
    }
    componentWillReceiveProps(nextProps) {
        console.log(nextProps.searchTxt);
        if (nextProps.searchTxt !== this.props.searchTxt) {
            this.orderSearch(nextProps.searchTxt)
        }
    }

    handleOrderPageNo = (m) => {
        this.setState({ orderPageNo: m.selected + 1 }, () => {
            this.allOrders(true);
        });
    }
    initSocket = () => {
        this.props.socketId.on("admin-order", () => {
            this.allOrders(false);
            palyNotifyTone()
        });
    }
    //  //////////////////////Api call-----
    allOrders = async (ldr) => {
        store.dispatch(loader(ldr));
        try {
            const aOrders = await getAllOrders(this.props.status, this.state.orderPageNo, this.props.perPage)
            // console.log(aOrders.data);
            this.props.orderCunt(aOrders.data.orderCount);
            this.setState({
                oderList: aOrders.data.data,
                pageCount: aOrders.data.pageInfo
            });
            store.dispatch(loader(false));
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }
    /*
    * Api
    * Orders Search
    */
    orderSearch = async (txt) => {
        try {
            const search = await searchOrders(txt);
            console.log(search.data.data);
            if(txt !== ""){
                this.setState({ oderList: search.data.data })
            }
            if(txt === ""){
                this.allOrders(false)
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    render() {
        return (
            <div>
                <div className="table-responsive text-capitalize">
                    <table className="table dash-table table-hover text-secondary">
                        <thead>
                            <tr className="text-left">
                                <th>Date/Amount</th>
                                <th>Order Id/Type</th>
                                <th>User</th>
                                <th>Outlet</th>
                                <th>Payment Mode</th>
                                <th>Payment Status</th>
                                <th>Order Status</th>
                                <th className="text-center">Delivery Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.oderList.map(item => {
                                    return <tr key={item._id} className="text-left">
                                    <td>
                                        <Moment format="DD-MM-YY HH:mm">
                                            {item.createdDate}
                                        </Moment>
                                        <br />
                                       <span className="font-12"> Amount <span className="font-12 badge badge-info">₹{item.orderAmount}</span></span>

                                    </td>
                                    <td>
                                        <Link to={`/orders/user-order-ditails/${item.orderId}`}>{item.orderId}</Link><br />
                                        <span className={item.orderType === 0 ? "text-success" : "text-warning"}>{item.orderType === 0 ? "Normal" : "Auction"}</span>

                                    </td>
                                    <td><Link to={`/users-management/user-details/${item.userId}`}>{item.user.full_name}</Link></td>
                                    <td><Link style={{ fontSize: "15px" }} to={`/outlets/products/${item.outletId}`}>{item.outlet?.outlet_name}</Link>
                                    <br/>
                                    <span className="font-12">{item.pickUpAddress.landmark}</span>
                                    </td>
                                    <td>{item.paymentMode}</td>
                                    <td>
                                        <span className={`badge ${item.paymentStatus === "SUCCESS" ? "badge-success" : "badge-danger"}`}>{item.paymentStatus}</span>
                                    </td>
                                    <td>
                                        <span className={`badge ${item.orderStatus === "completed" ? "badge-success" : item.orderStatus === "preparing" ?
                                            "badge-primary" : item.orderStatus === "ready-for-pickup" ?
                                            "badge-info": item.orderStatus === "out-for-delivery" ?
                                                "badge-secondary" : item.orderStatus === "submitted" ?
                                                    "badge-warning" : "badge-danger"}`}>
                                            {item.orderStatus}
                                        </span>
                                    </td>
                                    <td className="text-center">{item.deliveryType === "take_away" ? "Take away" : item.deliveryStatus}
                                    <br/>
                                    <span className="font-12">{item.deliveryPatner}</span>
                                    </td>
                                </tr>
                                })}
                        </tbody>
                    </table>
                    <div>
                        <ReactPaginate
                            previousLabel={'<<'}
                            nextLabel={'>>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            pageCount={this.state.pageCount.maxPageNo}
                            onPageChange={this.handleOrderPageNo}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
                {/* <div className="table-responsive text-capitalize">
                    {this.state.searchOrderData.length !== 0 || this.state.searchOrderData === "undefined" ? "HI" : "Kee"}
                    <table className="table dash-table table-hover text-secondary">
                        <thead>
                            <tr className="text-left">
                                <th>Date/Amount</th>
                                <th>Order Id/Type</th>
                                <th>User</th>
                                <th>Outlet</th>
                                <th>Payment Mode</th>
                                <th>Payment Status</th>
                                <th>Order Status</th>
                                <th className="text-center">Delivery Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.oderList.map(item => {
                                return <tr key={item._id} className="text-left">
                                    <td>
                                        <Moment format="DD-MM-YY HH:mm">
                                            {item.createdDate}
                                        </Moment><br />
                                        <span className="font-12">Amount ₹{item.orderAmount}</span>

                                    </td>
                                    <td>
                                        <Link to={`/orders/user-order-ditails/${item.orderId}`}>{item.orderId}</Link><br />
                                        <span className="font-12">{item.orderType === 0 ? "Normal" : "Auction"}</span>

                                    </td>
                                    <td><Link to={`/users-management/user-details/${item.userId}`}>{item.user.full_name}</Link></td>
                                    <td><Link style={{ fontSize: "15px" }} to={`/outlets/products/${item.outletId}`}>{item.outlet.outlet_name}</Link></td>
                                    <td>{item.paymentMode}</td>
                                    <td>
                                        <span className={`badge ${item.paymentStatus === "SUCCESS" ? "badge-success" : "badge-danger"}`}>{item.paymentStatus}</span>
                                    </td>
                                    <td>
                                        <span className={`badge ${item.orderStatus === "completed" ? "badge-success" : item.orderStatus === "preparing" ?
                                            "badge-primary" : item.orderStatus === "out-for-delivery" ?
                                                "badge-info" : item.orderStatus === "submitted" ?
                                                    "badge-warning" : "badge-danger"}`}>
                                            {item.orderStatus}
                                        </span>
                                    </td>
                                    <td className="text-center">{item.deliveryStatus}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    <div>
                        <ReactPaginate
                            previousLabel={'<<'}
                            nextLabel={'>>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            pageCount={this.state.pageCount.maxPageNo}
                            onPageChange={this.handleOrderPageNo}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div> */}
            </div>
        );
    }
}

export default connect(mapStateToProps)(AllOrderTable);