import React, { useEffect } from 'react';
import ActionButton from '../action-button';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';



export default function ManagerTable(props) {
  useEffect(() => {
    props.allUserType("manager");
  }, []);

  return (<>
    <table className="table table-hover">
      <thead>
        <tr>
          <th>Image</th>
          <th>Users Name</th>
          <th>Mobile No.</th>
          <th>Email</th>
          <th style={{ width: "26%" }}>Residential Address</th>
          <th>Current Status</th>
          <th className="text-center">Edit</th>
        </tr>
      </thead>
      <tbody>
        {
          props.data.data.map(item => {
            return <tr key={item._id}>
              <td>
                <img src={item.image ? item.image : "/assate/images/profile.png"} className="img-fluid user-profile" alt="hungry top" />
              </td>
              <td><Link to={`/users-management/user-details/${item._id}`}>{item.full_name}</Link><p className="font-10 m-0">{item.referral_code}</p></td>
              <td>+91-{item.mobile}</td>
              <td>{item.email}</td>
              <td><div>{item.address_details[0].address}</div></td>
              <td className="text-center">{item.isActive ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Deactive</span>}</td>
              <td>
                <span className="user-table-auction-item">
                  <Link to={`/users-management/add-user/${item._id}`}>
                    <ActionButton>
                      <i className="demo-icon icon-edit">&#xe80f;</i>
                    </ActionButton>
                  </Link>
                  {/* <ActionButton>
                    <i className="demo-icon icon-delete">&#xe80e;</i>
                  </ActionButton> */}
                </span>
              </td>
            </tr>
          })
        }
      </tbody>
    </table>
    <div>
      <ReactPaginate
        previousLabel={'<<'}
        nextLabel={'>>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        pageCount={props.data.pageInfo.maxPageNo}
        onPageChange={props.userPageFunc}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </div>
  </>
  );
}
