import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { getAlluserOrders } from '../../api/apiService';
import { connect } from 'react-redux';
import { loader } from '../../Actions/index';
import store from '../../store';
import Moment from 'react-moment';

const mapStateToProps = (state) => {
  return {
      loaderDisplay: state.loaderDisplay
  }
}
class UserOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oderList: [],
      pageCount: [],
      orderPageNo: 1,
    }
    this.userAllOrders();
  }
  handleOrderPageNo = (m) => {
    this.setState({ orderPageNo: m.selected + 1 }, () => {
      this.userAllOrders();
    });
  }

  //  //////////////////////Api call-----
  userAllOrders = async () => {
    store.dispatch(loader(true));
    try {
      const userOrders = await getAlluserOrders(this.props.userId, this.state.orderPageNo, 20)
      this.setState({
        oderList: userOrders.data.data,
        pageCount: userOrders.data.pageInfo
      });
      store.dispatch(loader(false));
    } catch (ex) {
      console.log(ex);
      store.dispatch(loader(false));
    }
  }
  render() {
    return (
      <div>
        <div className="table-responsive text-capitalize">
          <table className="table dash-table table-hover text-secondary">
            <thead>
              <tr className="text-left">
                <th>Date/Amount</th>
                <th>Order Id/Type</th>
                <th>Outlet</th>
                <th>Payment Mode</th>
                <th>Payment Status</th>
                <th>Order Status</th>
                <th className="text-center">Delivery Status</th>
              </tr>
            </thead>
            <tbody>
              {this.state.oderList.map(item => {
                return <tr key={item._id} className="text-left">
                  <td>
                    <Moment format="DD-MM-YY HH:mm">
                      {item.createdDate}
                    </Moment><br />
                    <span className="font-12">Amount ₹{item.orderAmount}</span>

                  </td>
                  <td>
                    <Link to={`/orders/user-order-ditails/${item.orderId}`}>{item.orderId}</Link><br />
                    <span className="font-12">{item.orderType === 0 ? "Normal" : "Auction"}</span>

                  </td>
                  <td><Link style={{ fontSize: "15px" }} to={`/outlets/products/${item.outletId}`}>{item.outlet.outlet_name}</Link></td>
                  <td>{item.paymentMode}</td>
                  <td>
                    <span className={`badge ${item.paymentStatus === "SUCCESS" ? "badge-success" : "badge-danger"}`}>{item.paymentStatus}</span>
                  </td>
                  <td>
                    <span className={`badge ${item.orderStatus === "completed" ? "badge-success" : item.orderStatus === "preparing" ?
                      "badge-primary" : item.orderStatus === "ready-for-pickup" ?
                      "badge-info": item.orderStatus === "out-for-delivery" ?
                        "badge-secondary" : item.orderStatus === "submitted" ?
                          "badge-warning" : "badge-danger"}`}>
                      {item.orderStatus}
                    </span>
                  </td>
                  <td className="text-center">{item.deliveryStatus}</td>
                </tr>
              })}
            </tbody>
          </table>
          <div>
            <ReactPaginate
              previousLabel={'<<'}
              nextLabel={'>>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              pageCount={this.state.pageCount.maxPageNo}
              onPageChange={this.handleOrderPageNo}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps)(UserOrderDetails);
