import React, { useState } from 'react';
import AppSettings from './AppSettings'
import AppImages from './appImages';
import SubscriptionPlan from './subscriptionPlan';
import OrderSettings from './OrderSettings';

const OffersPage = () => {
    const [value, setValue] = useState(0);

    const handleChange = (newValue) => {
        setValue(newValue);
    };
    return (
        <div className='mt-2'>
            <div className="px-4">
                <button className={value === 0 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(0)}>App Images</button>
                {/* <button className={value === 1 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(1)}>App Settings</button> */}
                <button className={value === 2 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(2)}>Order Settings</button>
                <button className={value === 3 ? 'panel-btn is-active' : 'panel-btn'} onClick={() => handleChange(3)}>Subscription Plan</button>
            </div>
            {value === 0 ? (
                <AppImages />
            ) : value === 1 ? (
                <AppSettings />
            ) : value === 2 ? (
                <OrderSettings />
            ) : (
                <SubscriptionPlan />
            )}
        </div>
    );
}



export default OffersPage