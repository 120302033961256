import React from 'react';
import { Modal } from "react-bootstrap";
import { ToastSuccess, ToastError } from "../component/toast-notification";
import {
  createNewOwner,
  verifyOtpCreateOwner,
  getAllOutlet,
  addOutletManager,
  userDetails,
  userUpdateByAdmin,
  updateUserVerifyOtpByAdmin
} from '../api/apiService';

class UserAddNew extends React.Component {

  isUpdate = false;
  isSubmitted = false;
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  constructor(props) {
    super(props);
    this.state = {
      addName: "",
      addNameError: "",
      addMobileNo: "",
      addMobileNoError: "",
      addEmail: "",
      addEmailError: "",
      addAddress: "",
      addAddressError: "",
      addMapLat: "",
      addMapLatError: "",
      addMapLong: "",
      addMapLongError: "",
      addOption: "Owner",
      addOptionError: "",
      showModal: "",
      referCode: localStorage.getItem('referCode'),
      referCodeError: "",
      otpData: "",
      key: "",
      showme: false,
      outlets: [],
      ownerId: "",
      userDetails: {}
    }
    if (this.props.match.params.userId) {
      this.isUpdate = true;
      this.getUserDetails(this.props.match.params.userId);
    } else {
      this.getOutList();
    }
  };

  getUserDetails(userId) {
    let self = this;
    userDetails(userId).then(function (response) {
      console.log(response.data.data);
      self.setState({
        addName: response.data.data.full_name,
        addMobileNo: response.data.data.mobile,
        addEmail: response.data.data.email,
        addAddress: response.data.data.address_details[0].address,
        addMapLat: response.data.data.address_details[0].lat,
        addMapLong: response.data.data.address_details[0].long,
        addOption: "Owner",
        referCode: response.data.data.referral_code,
      });
    })
      .catch(function (error) {
        //ToastError(error.response.data.message);
        console.log(error);
      });
  }


  validOTP() {
    if (this.state.otpData.length === 4) {
      return true;
    } else {
      return false;
    }
  }

  handleChangeOTP = (event) => {
    this.setState({
      otpData: event.target.value
    }, () => {
      return this.isSubmitted ? this.validOTP() : false;
    });
  }
  handleChangeAddName = (event) => {
    this.setState({
      addName: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }
  handleChangeAddMobileNo = (event) => {
    this.setState({
      addMobileNo: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }
  handleChangeAddEmail = (event) => {
    this.setState({
      addEmail: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }
  handleChangeAddress = (event) => {
    this.setState({
      addAddress: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }
  handleChangeMapLat = (event) => {
    this.setState({
      addMapLat: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }
  handleChangeMapLong = (event) => {
    this.setState({
      addMapLong: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }
  handleChangeRefer = (event) => {
    this.setState({
      referCode: event.target.value
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }
  handleChangeSelectBox = (event) => {
    let isManager = false;
    if ("Manager" === event.target.value) {
      isManager = true;
    } else {
      isManager = false;
    }
    this.setState({
      addOption: event.target.value,
      showme: isManager
    }, () => {
      return this.isSubmitted ? this.valid() : false;
    });
  }
  handleChangeOutlet = (event) => {
    console.log(event.target.value);
    if ("0" === event.target.value) {
      this.setState({
        addOptionError: "Please select Outlet",
        ownerId: event.target.value,
      }, () => {
        return this.isSubmitted ? this.valid() : false;
      });
    } else {
      this.setState({
        addOptionError: "",
        ownerId: event.target.value,
      }, () => {
        return this.isSubmitted ? this.valid() : false;
      });
    }

  }
  // validation
  valid() {
    this.setState(
      {
        addNameError: "",
        addMobileNoError: "",
        addEmailError: "",
        addAddressError: "",
        addMapLatError: "",
        addMapLongError: "",
        referCodeError: "",
      }
    );


    let checked = true;
    /* if (this.state.addOption === 'Manager') {
        //this.setState({ addNameError: "Please enter name" });
        checked = false;
    } */
    if (this.state.addName.length < 1) {
      this.setState({ addNameError: "Please enter name" });
      checked = false;
    }
    if (this.state.referCode.length < 1) {
      this.setState({ referCodeError: "Please enter refer code" });
      checked = false;
    }
    if (!/^(\+\d{1,3}[- ]?)?\d{10}$/i.test(this.state.addMobileNo)) {
      this.setState({ addMobileNoError: "Please enter mobile no" });
      checked = false;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,4}$/i.test(this.state.addEmail)) {
      this.setState({ addEmailError: "Please enter email" });
      checked = false;
    }
    if (!/^\s*\S+(?:\s+\S+){2}/i.test(this.state.addAddress)) {
      this.setState({ addAddressError: "Please enter address" });
      checked = false;
    }
    if (!/^-?([1-8]?\d(?:\.\d{1,})?|90(?:\.0{1,6})?)$/i.test(this.state.addMapLat)) {
      this.setState({ addMapLatError: "Please enter lat" });
      checked = false;
    }
    if (!/^-?([1-8]?\d(?:\.\d{1,})?|90(?:\.0{1,6})?)$/i.test(this.state.addMapLong)) {
      this.setState({ addMapLongError: "Please enter long" });
      checked = false;
    }
    return checked;
  }

  getOutList() {
    var self = this;
    getAllOutlet().then(function (response) {
      self.setState({ outlets: response.data.data });
    })
      .catch(function (error) {
        console.log(error)
        ToastError(error.response.data.message);
      });
  }

  // submitedOTP = () => {
  //   if (this.validOTP()) {
  //     var self = this;
  //     const data = {
  //       "key": this.state.key,
  //       "otp": this.state.otpData
  //     }
  //     verifyOtpCreateOwner(data).then(function (response) {
  //       ToastSuccess(response.data.message);
  //       self.props.history.goBack();
  //     })
  //       .catch(function (error) {
  //         console.log(error);
  //         ToastError(error.response.data.message);
  //       });
  //   }
  // }
  submitedOTP = () => {
    if (this.validOTP()) {
      var self = this;
      const data = {
        "key": this.state.key,
        "otp": this.state.otpData
      }
      if (this.isUpdate) {
      updateUserVerifyOtpByAdmin(this.props.match.params.userId, data).then(function (response) {
          ToastSuccess(response.data.message);
          self.props.history.goBack();
        })
          .catch(function (error) {
            console.log(error);
            ToastError(error.response.data.message);
          });
      } else {
        verifyOtpCreateOwner(data).then(function (response) {
          ToastSuccess(response.data.message);
          self.props.history.goBack();
        })
          .catch(function (error) {
            console.log(error);
            ToastError(error.response.data.message);
          });
      }
    }
  }
  
  submited = () => {

    this.isSubmitted = true;
    this.setState(
      {
        addNameError: "",
        addMobileNoError: "",
        addEmailError: "",
        addAddressError: "",
        addMapLatError: "",
        addMapLongError: "",
        referCodeError: "",
      }
    );
    if (this.valid()) {
      this.isSubmitted = false;
      var self = this;
      const data = {
        "full_name": this.state.addName,
        "mobile": this.state.addMobileNo,
        "email": this.state.addEmail,
        "referral_code": this.state.referCode,
        "address_details": {
          "address": this.state.addAddress,
          "lat": this.state.addMapLat,
          "long": this.state.addMapLong
        }
      };
      if (this.isUpdate) {
        userUpdateByAdmin(this.props.match.params.userId, data).then(function (response) {
          self.setState({ key: response.data.data.key })
          ToastSuccess(response.data.message);
          self.openModal();
        })
          .catch(function (error) {
            console.log(error);
            ToastError(error.response.data.message);
          });
      } else {
        if (this.state.addOption === 'Manager') {
          addOutletManager(this.state.ownerId, data).then(function (response) {
            self.setState({ key: response.data.data.key })
            ToastSuccess(response.data.message);
            self.openModal();
          })
            .catch(function (error) {
              console.log(error);
              ToastError(error.response.data.message);
            });
        } else {
          createNewOwner(data).then(function (response) {
            self.setState({ key: response.data.data.key })
            ToastSuccess(response.data.message);
            self.openModal();
          })
            .catch(function (error) {
              console.log(error);
              ToastError(error.response.data.message);
            });
        }
      }


    }
  }

  render() {
    return (
      <div>
        <div className="my-3">
          <button onClick={() => this.submited()}
            type="button"
            className="btn btn-verify" data-toggle="modal" data-target={this.state.showModal}>{!this.isUpdate ? 'Verify' : 'Update'}
          </button>
          <button type="button" onClick={() => this.props.history.goBack()} className="btn btn-cancel">Cancel</button>
        </div>
        <div className="container-fluid add-user-container">
          <h5 className='mt-4 mb-3'>Common information</h5>
          <div className="row">
            <div className="col-sm-3">
              <div>
                <label className="font-12 m-0">Add users</label>
                <select onChange={ev => this.handleChangeSelectBox(ev)}
                  className="form-control"
                  disabled={this.isUpdate ? true : false}
                  id="sel1">
                  <option key="Owner" value="Owner">Restaurant Owner</option>
                  {/* <option key="Manager"
                    value="Manager">Manager
                  </option> */}
                </select>
                <p className="error-msg"></p>
              </div>
            </div>
            <div className="col-sm-6">
              <div style={{ "display": !this.state.showme ? "none" : "block" }}>
                <label className="font-12 m-0">Outlet select</label>
                <select className="form-control" onChange={ev => this.handleChangeOutlet(ev)}
                  style={this.state.addOptionError !== "" ? { border: "1px solid red" } : {}}>
                  <option value="0">Select outlet</option>
                  {this.state.outlets.map(m => {
                    return <option key={m._id} value={m._id}>{m.outlet_name}</option>
                  })}
                </select>
                <p className="error-msg">{this.state.addOptionError}</p>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0">Name</label>
                <input onChange={ev => this.handleChangeAddName(ev)}
                  type="text"
                  disabled={this.isUpdate ? false : false}
                  value={this.state.addName}
                  className="form-control"
                  style={this.state.addNameError !== "" ? { border: "1px solid red" } : {}}
                  placeholder="Full Name" id="usr" />
              </div>
              <p className="error-msg">{this.state.addNameError}</p>
            </div>
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0">Mobile no</label>
                <input onChange={ev => this.handleChangeAddMobileNo(ev)}
                  type="text"
                  disabled={this.isUpdate ? false : false}
                  value={this.state.addMobileNo}
                  className="form-control"
                  style={this.state.addMobileNoError !== "" ? { border: "1px solid red" } : {}}
                  placeholder="(040)690-6188" id="usr2" />
                <i className="demo-icon icon-check-circle"
                  style={/^(\+\d{1,3}[- ]?)?\d{10}$/i.test(this.state.addMobileNo) ? { color: "green" } : {}}>
                  &#xe809;
                </i>
              </div>
              <p className="error-msg">{this.state.addMobileNoError}</p>
            </div>
            <div className="col-sm-6 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0">Email</label>
                <input onChange={ev => this.handleChangeAddEmail(ev)}
                  type="text"
                  disabled={this.isUpdate ? false : false}
                  value={this.state.addEmail}
                  className="form-control"
                  style={this.state.addEmailError !== "" ? { border: "1px solid red" } : {}}
                  placeholder="bobby.jackson@mail.com" id="usr3" />
                <i className="demo-icon icon-check-circle"
                  style={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,4}$/i.test(this.state.addEmail) ? { color: "green" } : {}}>
                  &#xe809;
                </i>
              </div>
              <p className="error-msg">{this.state.addEmailError}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0">Address</label>
                <input onChange={ev => this.handleChangeAddress(ev)}
                  type="text"
                  disabled={this.isUpdate ? true : false}
                  value={this.state.addAddress}
                  className="form-control"
                  style={this.state.addAddressError !== "" ? { border: "1px solid red" } : {}}
                  placeholder="99/8 Jessore Road, Satgachi, South Dum Dum, Kolkata - 700028" id="usr4" />
              </div>
              <p className="error-msg">{this.state.addAddressError}</p>
            </div>
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0">Latitude</label>
                <input onChange={ev => this.handleChangeMapLat(ev)}
                  type="text"
                  disabled={this.isUpdate ? true : false}
                  value={this.state.addMapLat}
                  className="form-control"
                  style={this.state.addMapLatError !== "" ? { border: "1px solid red" } : {}}
                  placeholder="Lat" id="usr5" />
              </div>
              <p className="error-msg">{this.state.addMapLatError}</p>
            </div>
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0">Longitude</label>
                <input onChange={ev => this.handleChangeMapLong(ev)}
                  type="text"
                  disabled={this.isUpdate ? true : false}
                  value={this.state.addMapLong}
                  className="form-control"
                  style={this.state.addMapLongError !== "" ? { border: "1px solid red" } : {}}
                  placeholder="Long" id="usr6" />
              </div>
              <p className="error-msg">{this.state.addMapLongError}</p>
            </div>
            <div className="col-sm-3 col-12">
              <div className="form-group m-0">
                <label className="font-12 m-0">Refer code</label>
                <input onChange={ev => this.handleChangeRefer(ev)}
                  value={this.state.referCode}
                  type="text"
                  disabled={this.isUpdate ? true : false}
                  className="form-control"
                  style={this.state.referCodeError !== "" ? { border: "1px solid red" } : {}}
                  placeholder="Refer code" id="usr7" />
              </div>
              <p className="error-msg">{this.state.referCodeError}</p>

            </div>
          </div>
        </div>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Enter OTP</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Please enter 4 digit OTP */}
            <div className="modal-body">
              <div className="form-group">
                <input onChange={ev => this.handleChangeOTP(ev)}
                  value={this.state.otpData}
                  type="text"
                  className="form-control mb-2"
                  placeholder="Enter OTP" 
                  autoFocus/>
                <button type="button" onClick={() => this.submitedOTP()} className="btn bg-btn-color w-100 px-4 py-2 text-black">Submit</button>
              </div>
              <p className="error-msg mb-3">{this.state.otpData.length === 4 ? '' : 'Please enter 4 digit OTP'}</p>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default UserAddNew;
